const lang_en = {
  languages: "en",
  language_en: "English (Beta)",
  language_th: "Thai",
  home: "Home",
  register: "Register",
  aboutUs: "About Us",
  contact: "Contact Us",
  services: "Services",
  article: "Article",
  login: "Login",
  login_ft: "Factor",
  login_sl: "Seller",
  login_ib: "IBilling ",
  uat: "UAT Environment",
  m_ab: "About us",
  m_ct: "Contact us",
  m_ft: "Factor",
  m_sl: "Seller",
  page_regis: {
    title1: "One Platform account is required for registration",
    title2: "Do you have a One Platform account?",
    title_: "One Platform account is required for registration",
    account: "Register Business",
    noaccount: "Register One Platform",
    login: "Login",
  },
  page_landing: {},
  page_contact: {
    title: "Send infomation to callback",
    bizType: "Business Type",
    bizKind: "Kind of business",
    bizName: "Business name",
    bizNumber: "Business numder",
    nameseller: "Name contact",
    phone: "Phone",
    email: "E-mail",
    swl_con_title: "Confirm to save data ?",
  },
  page_register: {
    stp1: "Register One Platform",
    stp2: "Register Business Account",
    stp3: "Success",
    title: "Register One Platform",
    personal: "Personal Information",
    nation: "Nationality",
    nation1: "Thai",
    nation2: "Foreigner",
    prefix_th: "Prefix (th)",
    name_th: "Name (th)",
    lastname_th: "Lastname (th)",
    prefix_en: "Prefix (en)",
    name_en: "Name (en)",
    lastname_en: "Lastname (en)",
    birthdate: "Birthdate",
    card_type: "Card Type",
    card_num: "Card Number",
    passport: "Passport Number",
    user: "User Information",
    email: "E-mail",
    phone: "Phonenumber",
    username: "Username",
    password: "Password",
    con_pass: "Confirm Password",
    agree: "acknowledge",
    and: "and",
    term: "Terms of Service ONE ID",
    privacy: "Privacy Policy",
    back: "Back to Homepage",
    reg: "Register",
    r_prefix: "Please enter prefix",
    r_prefix2: "Please enter prefix",
    r_password: "Please enter password",
    r_password2: "Must contain at least 1 number and 1 alphabet, at least 8 length.",
    r_conrext: "Please confirm your password",
    r_context2: "Must contain at least 1 number and 1 alphabet, at least 8 length",
    r_user: "Please enter username",
    r_user2: "At least 8 characters and no special characters.",
    r_name: "Please enter name (th)",
    r_name2: "Incoret",
    r_lastname: "Please enter lastname (th)",
    r_lastname2: "Incoret",
    r_name_en: "Please enter name (en)",
    r_name_en2: "Incoret",
    r_lastname_en: "Please enter lastname (en)",
    r_lastname_en2: "Incoret",
    r_bd: "Please enter birthdate",
    r_type: "Please enter type card",
    r_id: "Please enter passport number",
    r_email: "Please enter email",
    r_email2: "Incorrect",
    r_number: "Please enter contact number",
    r_number2: "Please enter 10 digits",
    r_pw: "Password is not match",
    itm_ID: "ID Card",
    itm_Pass: "Passport",
    swl_title: "Confirm registration?",
    swl_con: "Confirm",
    swl_can: "Cancel",
    swl_err: "Username or password incorrect plaese try agian ",
    af_title: "Register Success",
    af_rem: "Please remember",
    af_username: "Username",
    af_email: "E-mail",
    af_tel: "Phone No.",
    af_confirm: "Please verify your identity via One chat application.",
    af_for: "to use for electronic certificate registration",
    af_download: "Download",
    af_man: "Guide for eKYC authentication via One Chat application",
  },
  page_regbiz: {
    stp1: "Register One Platform",
    stp2: "Register Business Account",
    stp3: "Success",
    bm: "Head office",
    ca: "Digital Certificate Enterprise",
    title: "Register Business Account",
    personal: "General Information",
    id_num: "Tax ID*",
    bizType_th: "Business Tpye (th)",
    bizType_en: "Business Tpye (en)",
    name_th: "Name (th)",
    name_en: "Name (en)",
    namedoc_th: "Name on Document (th)",
    namedoc_en: "Name on Document (en)",
    branch_main: "Head office",
    branch_sub: "Branch",
    branch: "ฺBranch",
    branch_no: "Branch Number",
    branch_name: "Branch Name",
    email: "Email*",
    tel: "Telephone Number",
    phone: "Phone Number",
    add_info: "Address Information",
    house_no: "House Number",
    bulding: "Bulding Name",
    floor: "Floor",
    house_code: "House code",
    bann: "Bann",
    moo: "Moo",
    soi: "Alley",
    fork: "Fork",
    street: "Street",
    zipcode: "Zipcode",
    province: "Provice",
    amphoe: "Amphoe",
    tambon: "Tambon",
    fax: "Fax Number",
    bizcer: "Business Certificate",
    proxy: "Proxy",
    back: "Back to Homepage",
    caname: "Name of Electronic Certificate Coordinator",
    calastname: "Lastname of Electronic Certificate Coordinator",
    catel: "Contact of Electronic Certificate Coordinator",
    caemail: "Email of Electronic Certificate Coordinator",
    cayear: "Contract of Electronic Certificate Coordinator",
    cy1: "1 year",
    cy2: "2 year",
    reg: "Register",
    reg2: "Save",
    swl_reg_title: "Confirm to register businees account?",
    swl_reg_title2: "Confirm to edit account?",
    swl_con: "Confirm",
    swl_can: "Cancel",
    swl_success: "Success",
    swl_successtext: "Register business account success.",
    swl_successtext2: "Save data success please close webview.",
    err_title: "Tax ID is not correct",
    err_title2: "ID duplicate",
    err_text: "Check the information again",
    err_text2: "ID number is duplicate",
    err_text3: "Your birthdate must to more than or equal 7 years old.",
    err_text4: "Email is duplicate",
    err_text5: "Usename is duplicate",
    err_text6: "title account are not match",
    err_text7: "The mobile no must be at least 10 characters.",
    err_text8: "The id card num must be at least 13 characters.",
    err_text9: "ID card is incorrect",
    swl_err: "Something wrong",
    swl_errtext: "Check the information again",
    r_id: "Please enter ID number",
    r_id2: "Please enter 13 digits",
    r_tt: "Please enter business type",
    r_nt: "Please enter busniess name (th)",
    r_nt2: "Incorrect",
    r_te: "Please enter business type",
    r_ne: "Please enter busniess name (en)",
    r_ne2: "Incorrect",
    r_dt: "Please enter name on document (th)",
    r_de: "Please enter name on document (en)",
    r_bc: "Please enter branch code",
    r_bn: "Please enter branch name",
    r_email: "Please enter email",
    r_email2: "Incorrect",
    r_tel: "Please enter telephone number",
    r_tel2: "Please enter 10 digits",
    r_phone: "Please enter phone number",
    r_phone2: "Please enter 10 digits",
    r_ad: "Please enter house number",
    r_zp: "Please enter zipcode",
    r_zp2: "Please enter 5 digits",
    r_pv: "Please enter province",
    r_ap: "Please enter amphoe",
    r_tb: "Please enter tambon",
    r_caname: "Please enter name",
    r_calname: "Plaese enter lastname",
  },
  page_login: {
    title: "Login to One Platform to register business account",
    username: "Username",
    password: "Password",
    forget: "Forget password",
    back: "Back to Homepage",
    login: "Login",
    swl_title: "Please fill the information completely",
    swl_err: "Username or password incorrect plaese try agian",
  },
};

export default lang_en;
