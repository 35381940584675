import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import vuetify from "./plugins/vuetify";
import "./registerServiceWorker";
import "@mdi/font/css/materialdesignicons.css";
import axios from "axios";
import VueAxios from "vue-axios";
import { i18n } from "./plugins/i18n.js";
import VueTelInputVuetify from "vue-tel-input-vuetify";
import "vue-tel-input/dist/vue-tel-input.css";
import VueScrollmagic from 'vue-scrollmagic';
import VueCookies from 'vue-cookies';
import AOS from "aos";
import 'aos/dist/aos.css'
import vmodal from 'vue-js-modal'
import VueMeta from 'vue-meta'
import VueLoadmore from 'vuejs-loadmore';
import Vue2Editor from "vue2-editor";

Vue.use(Vue2Editor);

Vue.use(VueCookies)
Vue.use(VueScrollmagic)
Vue.use(VueMeta)
Vue.use(vmodal, { componentName: 'modal', dialog: true  })
Vue.use(VueLoadmore)
Vue.use(VueTelInputVuetify, {
  vuetify,
});

Vue.config.productionTip = false;

Vue.use(VueAxios, axios);
new Vue({
  router,
  store,
  vuetify,
  i18n,
  mounted() {
    AOS.init();
  },
  render: (h) => h(App),
}).$mount("#app");
