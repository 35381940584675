const lang_th = {
  languages: "th",
  language_en: "อังกฤษ (ทดสอบ)",
  language_th: "ไทย",
  home: "หน้าหลัก",
  register: "ลงทะเบียน",
  article: "บทความ",
  aboutUs: "เกี่ยวกับเรา",
  services: "บริการของเรา",
  contact: "ติดต่อเรา",
  login: "เข้าสู่ระบบ",
  login_ft: "ผู้ให้สินเชื่อ (Factor)",
  login_sl: "ผู้ขอสินเชื่อ (Seller)",
  login_ib: "ระบบวางบิล (ทดสอบ)",
  uat: "ระบบทดสอบ",
  m_ab: "เกี่ยวกับเรา",
  m_ct: "ติดต่อเรา",
  m_ft: "ผู้ให้สินเชื่อ (Factor)",
  m_sl: "ผู้ขอสินเชื่อ (Seller))",
  page_regis: {
    title1: " ลงทะเบียนบัญชี One Platform เพื่อใช้งานระบบ e-Factoring",
    title2: " คุณมีบัญชี OnePlatform หรือไม่?",
    title3: " เข้าสู่ระบบ One Platform เพื่อใช้งานระบบ e-Factoring",
    account: "ลงทะเบียนนิติบุคคล",
    noaccount: "ลงทะเบียนผู้ใช้งาน",
    login: "เข้าสู่ระบบ",
  },
  page_landing: {},
  page_contact: {
    title: "ส่งข้อมูลเพื่อรอการติดต่อกลับ",
    bizType: "ประเภทธุรกิจ",
    bizKind: "ลักษณะธุรกิจ",
    bizName: "ชื่อกิจการ",
    bizNumber: "เลขทะเบียนนิติบุคคล",
    nameseller: "ชื่อผู้ติดต่อ",
    phone: "เบอร์ติดต่อ",
    email: "อีเมลล์",
    swl_con_title: "ยืนยันการบันทึกข้อมูลหรือไม่ ?",
  },
  page_register: {
    stp1: "ลงทะเบียน One Platform",
    stp2: "ลงทะเบียนบัญชีนิติบุคคล",
    stp3: "เสร็จสิ้นการลงทะเบียน",
    title: "ลงทะเบียนผู้ใช้งาน One Platform",
    personal: "ข้อมูลส่วนบุคคล",
    nation: "สัญชาติ",
    nation1: "ไทย",
    nation2: "ต่างชาติ",
    prefix_th: "คำนำหน้าชื่อ (ไทย)",
    name_th: "ชื่อ (ไทย)",
    lastname_th: "นามสกุล (ไทย)",
    prefix_en: "คำนำหน้าชื่อ (อังกฤษ)",
    name_en: "ชื่อ (อังกฤษ)",
    lastname_en: "นามสกุล (อังกฤษ)",
    birthdate: "วันเกิด",
    card_type: "ชนิดบัตร",
    card_num: "รหัสบัตร 13 หลัก",
    passport: "เลขที่หนังสือเดินทาง",
    user: "ข้อมูลผู้ใช้งาน",
    email: "อีเมล",
    phone: "เบอร์โทรศัพท์",
    username: "ชื่อผู้ใช้",
    password: "รหัสผ่าน",
    con_pass: "ยืนยันรหัสผ่าน",
    agree: "รับทราบ",
    and: "และ",
    term: "ข้อกำหนดการใช้บริการ ONE ID",
    privacy: "นโยบายคุ้มครองข้อมูลส่วนบุคคล",
    back: "กลับหน้าหลัก",
    reg: "ลงทะเบียน",
    r_prefix: "โปรดระบุคำนำหน้าชื่อ(ภาษาไทย)",
    r_prefix2: "โปรดระบุคำนำหน้าชื่อ(อังกฤษ)",
    r_password: "โปรดระบุรหัสผ่าน",
    r_password2: "ต้องมีตัวเลข และตัวอักษรภาษาอังกฤษ อย่างน้อย 1 ตัว ความยาวอย่างน้อย 8",
    r_conrext: "โปรดยืนยันรหัสผ่าน",
    r_context2: "ต้องมีตัวเลข และตัวอักษรภาษาอังกฤษ อย่างน้อย 1 ตัว ความยาวอย่างน้อย 8",
    r_user: "โปรดระบุข้อมูลชื่อผู้ใช้",
    r_user2: "ตัวอักษรอย่างน้อย 8 ตัว และห้ามมีอักขระพิเศษ",
    r_name: "โปรดระบุชื่อ(ภาษาไทย)",
    r_name2: "ข้อมูลไม่ถูกต้อง",
    r_lastname: "โปรดระบุนามสกุล(ภาษาไทย)",
    r_lastname2: "ข้อมูลไม่ถูกต้อง",
    r_name_en: "โปรดระบุชื่อ(อังกฤษ)",
    r_name_en2: "ข้อมูลไม่ถูกต้อง",
    r_lastname_en: "โปรดระบุนามสกุล(อังกฤษ)",
    r_lastname_en2: "ข้อมูลไม่ถูกต้อง",
    r_bd: "โปรดระบุข้อมูลวันเกิด",
    r_type: "โปรดระบุชนิดบัตร",
    r_id: "โปรดระบุข้อมูล",
    r_email: "โปรดระบุอีเมล",
    r_email2: "ข้อมูลไม่ถูกต้อง",
    r_number: "โปรดระบุเบอร์ติดต่อ",
    r_number2: "โปรดระบุตัวเลข 10 หลัก",
    r_pw: "รหัสผ่านไม่ตรงกัน",
    itm_ID: "บัตรประชาชน",
    itm_Pass: "หนังสือเดินทาง",
    swl_title: "ยืนยันการลงทะเบียนหรือไม่ ?",
    swl_con: "ยืนยัน",
    swl_can: "ยกเลิก",
    swl_err: "ชื่อผู้ใช้งานหรือรหัสผ่านไม่ถูกต้อง กรุณากรอกข้อมูลใหม่",
    af_title: "ลงทะเบียนสำเร็จ",
    af_rem: "โปรดจำข้อมูลนี้",
    af_username: "ชื่อผู้ใช้งาน",
    af_email: "อีเมล",
    af_tel: "เบอร์โทรศัพท์",
    af_confirm: "กรุณายืนยังตัวตนผ่านแอปพลิเคชั่น One chat",
    af_for: "เพื่อใช้ในการลงทะเบียนใบรับรองอิเล็กทรอนิกส์",
    af_download: "ดาวน์โหลด",
    af_man: "คู่มือการยืนยันตัวตนระบบ eKYC ผ่านแอปพลิเคชั่น One Chat",
  },
  page_regbiz: {
    stp1: "ลงทะเบียน One Platform",
    stp2: "ลงทะเบียนบัญชีนิติบุคคล",
    stp3: "เสร็จสิ้นการลงทะเบียน",
    bm: "สำนักงานใหญ่",
    title: "ลงทะเบียนนิติบุคคล",
    ca: "ข้อมูลผู้ประสานงาน เพื่อขอรับใบรับรองอิเล็กทรอนิกส์ประเภทนิติบุคคล (Digital Certificate Enterprise)",
    personal: "ข้อมูลทั่วไป",
    id_num: "เลขประจำตัวผู้เสียภาษี 13 หลัก*",
    bizType_th: "ประเภทนิติบุคคล (ไทย)*",
    bizType_en: "ประเภทนิติบุคคล (อังกฤษ)*",
    name_th: "ชื่อผู้ประกอบการ (ไทย)*",
    name_en: "ชื่อผู้ประกอบการ (อังกฤษ)*",
    namedoc_th: "ชื่อสำหรับแสดงบนเอกสาร(ไทย)*",
    namedoc_en: "ชื่อสำหรับแสดงบนเอกสาร(อังกฤษ)*",
    branch_main: "สำนักงานใหญ่",
    branch: "สาขา",
    branch_sub: "สาขาอื่นๆ",
    branch_no: "รหัสสาขา",
    branch_name: "ชื่อสาขา",
    email: "อีเมล*",
    tel: "เบอร์โทรศัพท์*",
    phone: "เบอร์โทรมือถือ*",
    add_info: "ข้อมูลที่อยู่",
    house_no: "เลขที่อยู่*",
    bulding: "อาคาร",
    floor: "ชั้นที่",
    house_code: "เลขที่ห้อง",
    bann: "ชื่อหมู่บ้าน",
    moo: "หมู่ที่",
    soi: "ตรอก/ซอย",
    fork: "แยก",
    street: "ถนน",
    zipcode: "รหัสไปรษณีย์",
    province: "จังหวัด",
    amphoe: "อำเภอ/เขต",
    tambon: "ตำบล/แขวง",
    fax: "โทรสาร",
    bizcer: "หนังสือรับรองนิติบุคคล",
    proxy: "หนังสือมอบอำนาจ",
    back: "กลับไปหน้าแรก",
    caname: "ชื่อ ผู้ประสานงานใบรับรองอิเล็กทรอนิกส์",
    calastname: "นามสกุล ผู้ประสานงานใบรับรองอิเล็กทรอนิกส์",
    catel: "เบอร์ติดต่อ",
    caemail: "อีเมล ผู้ประสานงานใบรับรองอิเล็กทรอนิกส์",
    cayear: "อายุการใช้งานใบรับรองอิเล็กทรอนิกส์",
    cy1: "1 ปี",
    cy2: "2 ปี",
    reg: "ลงทะเบียน",
    reg2: "บันทึกข้อมูล",
    swl_reg_title: "ยืนยันการลงทะเบียนบัญชีนิติบุคคลหรือไม่",
    swl_reg_title2: "ยืนยันการบันทึกข้อมูลผู้ใช้งานหรือไม่",
    swl_con: "ยืนยัน",
    swl_can: "ยกเลิก",
    swl_success: "ดำเนินการสำเร็จ",
    swl_successtext: "ลงทะเบียนบัญชีนิติบุคคลสำเร็จ",
    swl_successtext2: "บันทึกข้อมูลสำเร็จ กรุณาปิด webview",
    err_title: "เลขที่ผู้เสียภาษีไม่ถูกต้อง",
    err_title2: "เลขที่ผู้เสียภาษีนี้ถูกใช้แล้ว",
    err_title3: "วันเกิดของคุณต้องมากกว่าหรือเท่ากับ 7 ปี",
    err_text2: "มีรหัสประชาชน หรือ เลขที่หนังสือเดินทางนี้อยู่แล้วในระบบ",
    err_text3: "วันเกิดของคุณต้องมากกว่าหรือเท่ากับ 7 ปี",
    err_text4: "มีอีเมลนี้อยู่แล้วในระบบ",
    err_text5: "มีชื่อผู้ใช้งานนี้อยู่แล้วในระบบ",
    err_text6: "คำนำหน้าชื่อภาษาไทยและอังกฤษไม่ตรงกัน",
    err_text7: "เบอร์โทรศัพท์ต้องไม่เกิน 10 ตัว",
    err_text8: "หมายเลขบัตรประชาชนต้องมีอักขระอย่างน้อย 13 ตัว",
    err_text9: "หมายเลขบัตรประชาชน / หนังสือเดินทาง ไม่ถูกต้อง",
    err_text: "ตรวจสอบข้อมูลอีกครั้ง",
    swl_err: "เกิดความผิดพลาด",
    swl_errtext: "ตรวจสอบข้อมูลอีกครั้ง",
    r_id: "โปรดระบุข้อมูล",
    r_id2: "โปรดระบุตัวเลข 13 หลัก",
    r_tt: "โปรดระบุประเภทนิติบุคคล",
    r_nt: "โปรดระบุชื่อผู้ประกอบการ(ไทย)",
    r_nt2: "ข้อมูลไม่ถูกต้อง",
    r_te: "โปรดระบุประเภทนิติบุคคล",
    r_ne: "โปรดระบุชื่อผู้ประกอบการ(อังกฤษ)",
    r_ne2: "ข้อมูลไม่ถูกต้อง",
    r_ent: "โปรดระบุชื่อ(ไทย)",
    r_elt: "โปรดระบุนามสกุล(ไทย)",
    r_ent2: "ข้อมูลไม่ถูกต้อง",
    r_ene: "โปรดระบุชื่อ(อังกฤษ)",
    r_ele: "โปรดระบุนามสกุล(อังกฤษ)",
    r_ene2: "ข้อมูลไม่ถูกต้อง",
    r_dt: "โปรดระบุชื่อสำหรับแสดงบนเอกสาร(ไทย)",
    r_de: "โปรดระบุชื่อสำหรับแสดงบนเอกสาร(อังกฤษ)",
    r_bc: "โปรดระบุรหัสสาขา",
    r_bn: "โปรดระบุชื่อสาขา",
    r_email: "โปรดระบุอีเมล",
    r_email2: "ข้อมูลไม่ถูกต้อง",
    r_tel: "โปรดระบุเบอร์โทรศัพท์",
    r_tel2: "โปรดระบุตัวเลข 10 หลัก",
    r_phone: "โปรดระบุเบอร์โทรศัพท์มือถือ",
    r_phone2: "โปรดระบุตัวเลข 10 หลัก",
    r_ad: "โปรดระบุเลขที่อยู่",
    r_zp: "โปรดระบุรหัสไปรษณีย์",
    r_zp2: "โปรดระบุตัวเลข 5 หลัก",
    r_pv: "โปรดระบุจังหวัด",
    r_ap: "โปรดระบุอำเภอ",
    r_tb: "โปรดระบุตำบล",
    r_caname: "โปรดระบุชื่อ",
    r_calname: "โปรระบุนามสกุล",
  },
  page_login: {
    title: "เข้าสู่ระบบ One Platform เพื่อลงทะเบียนนิติบุคคล",
    username: "ชื่อผู้ใช้งาน",
    password: "รหัสผ่าน",
    forget: "ลืมรหัสผ่าน",
    back: "กลับหน้าหลัก",
    login: "เข้าสู่ระบบ",
    swl_title: "กรุณากรอกข้อมูลให้ครบถ้วน",
    swl_err: "ชื่อผู้ใช้งานหรือรหัสผ่านไม่ถูกต้อง กรุณากรอกข้อมูลใหม่",
  },
};

export default lang_th;
