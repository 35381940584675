import Vue from "vue";
import VueRouter from "vue-router";
import VueGtag from "vue-gtag";
import { i18n } from "../plugins/i18n.js";
import VueMeta from "vue-meta";
import axios from "axios";
Vue.use(VueRouter);
Vue.use(VueMeta);

const routes = [
  {
    path: "/",
    name: "NewLanding",
    component: () => import("../views/PageLandingNew.vue"),
    beforeEnter: ifMaintenance,
    meta: {
      title: "e-Factoring Platform - ระบบขอสินเชื่ออิเล็กทรอนิกส์ เปลี่ยนบิลให้เป็นเงินสด ด้วยเครื่องมือ บริหารสภาพคล่องธุรกิจ",
      metaTags: [
        {
          name: "keyword",
          content: "digital factoring,factoring,บริการรับซื้อหนี้การค้า,invoice,inet,สินเชื่อออนไลน์,ทุณทรัพย์",
        },
        {
          name: "description",
          content:
            "e-Factoring บริการรับซื้อหนี้การค้าเป็นสินเชื่อระยะสั้นที่จะทำให้คุณเปลี่ยนบิลหรือเอกกสาร ทางการค้าที่อยู่ในมือคุณเป็นเงินสดได้อย่างง่ายดาย โดยผ่านการตรวจสอบจากฐานข้อมูลกลางและมาตรฐานใบแจ้งหนี้ดิจิทัล",
        },

        {
          name: "author",
          content: "Internet Thailand Public Company Limited.",
        },
      ],
    },
  },
  {
    path: "/blog",
    name: "BlogAll",
    component: () => import("../views/PageBlogAllNew.vue"),
    beforeEnter: ifMaintenance,
  },
  {
    path: '/blog/:id/:slug',
    name: 'blog-post',
    component: () => import("../views/PageBlogDetail.vue"),
    beforeEnter: ifMaintenance,
  },
  // {
  //   path: "/blog",
  //   name: "BlogAll",
  //   component: () => import("../views/PageBlogAll.vue"),
  //   beforeEnter: ifMaintenance,
  // },
  // {
  //   path: "/blog/:id",
  //   name:"Blog",
  //   component: () => import("../views/PageBlog.vue"),
  // },
  {
    path: "/blog/crowd-funding",
    name: "crowd-funding",
    component: () => import("../views/page_crowd_funding.vue"),
    beforeEnter: ifMaintenance,
  },
  {
    path: "/blog/ifs",
    name: "IFS",
    component: () => import("../views/page_IFS.vue"),
    beforeEnter: ifMaintenance,
  },
  {
    path: "/blog/digital-factoring",
    name: "digital-factoring",
    component: () => import("../views/page_digital_factoring.vue"),
    beforeEnter: ifMaintenance,
  },
  {
    path: "/blog/funding-societies",
    name: "funding-societies",
    component: () => import("../views/page_funding_societies.vue"),
    beforeEnter: ifMaintenance,
  },
  {
    path: "/blog/supply-chain",
    name: "supply-chain",
    component: () => import("../views/page_supplychain.vue"),
  },
  {
    path: "/blog/supply-chain-finance",
    name: "supply-chain-finance",
    component: () => import("../views/page_supply_chain_finance.vue"),
    beforeEnter: ifMaintenance,
  },
  {
    path: "/blog/smes",
    name: "smes",
    component: () => import("../views/page_smes.vue"),
    beforeEnter: ifMaintenance,
  },
  {
    path: "/aboutus",
    name: "About",
    component: () => import("../views/PageAboutUs.vue"),
    beforeEnter: ifMaintenance,
  },
  {
    path: "/contact",
    name: "Contact",
    component: () => import("../views/PageContact.vue"),
    beforeEnter: ifMaintenance,
  },
  {
    path: "/services",
    name: "Service",
    component: () => import("../views/PageService.vue"),
    beforeEnter: ifMaintenance,
  },
  {
    path: "/service-d2bill",
    name: "service-d2bill",
    component: () => import("../views/Page_d2bill.vue"),
    beforeEnter: ifMaintenance,
  },
  {
    path: "/green-loan",
    name: "green-loan",
    component: () => import("../views/Page_greenLoan.vue"),
    beforeEnter: ifMaintenance,
  },
  {
    path: "/services/:id",
    name: "Service",
    component: () => import("../views/PageServiceById.vue"),
    beforeEnter: ifMaintenance,
  },
  {
    path: "/bsl",
    name: "bsl",
    component: () => import("../views/PageBsl.vue"),
    beforeEnter: ifMaintenance,
  },
  {
    path: "/bsl-hire-purchase",
    name: "bsl-hire-purchase",
    component: () => import("../views/PageBslHire.vue"),
    beforeEnter: ifMaintenance,
  },
  {
    path: "/bsl-leasing",
    name: "bsl-leasing",
    component: () => import("../views/PageBslLeasing.vue"),
    beforeEnter: ifMaintenance,
  },
  {
    path: "/bsl-auto-mp",
    name: "bsl-auto-mp",
    component: () => import("../views/PageBslAuto.vue"),
    beforeEnter: ifMaintenance,
  },
  {
    path: "/leasing",
    name: "leasing",
    component: () => import("../views/PageLeasing.vue"),
    beforeEnter: ifMaintenance,
  },
  {
    path: "/hire-purchase",
    name: "hire-purchase",
    component: () => import("../views/PageHire.vue"),
    beforeEnter: ifMaintenance,
  },
  {
    path: "/auto-mp",
    name: "auto-mp",
    component: () => import("../views/PageAuto.vue"),
    beforeEnter: ifMaintenance,
  },
  {
    path: "/old",
    name: "LanddingOld",
    component: () => import("../components/NewLandding.vue"),
    beforeEnter: ifMaintenance,
    meta: {
      title: "Digital Factoring Platform - ระบบขอสินเชื่ออิเล็กทรอนิกส์ เปลี่ยนบิลให้เป็นเงินสด ด้วยเครื่องมือ บริหารสภาพคล่องธุรกิจ",
      metaTags: [
        {
          name: "keyword",
          content: "digital factoring,factoring,บริการรับซื้อหนี้การค้า,invoice,inet",
        },
        {
          name: "description",
          content:
            "Digital Factoring บริการรับซื้อหนี้การค้าเป็นสินเชื่อระยะสั้นที่จะทำให้คุณเปลี่ยนบิลหรือเอกกสาร ทางการค้าที่อยู่ในมือคุณเป็นเงินสดได้อย่างง่ายดาย โดยผ่านการตรวจสอบจากฐานข้อมูลกลางและมาตรฐานใบแจ้งหนี้ดิจิทัล",
        },

        {
          name: "author",
          content: "Internet Thailand Public Company Limited.",
        },
      ],
    },
  },
  {
    name: "RegieterPage",
    path: "/register-page",
    component: () => import("../views/RegisterPage.vue"),
    beforeEnter: ifMaintenance,
    meta: {
      title: "Digital Factoring Platform - บริการรับซื้อ หนี้การค้าเป็นสินเชื่อระยะสั้นแบบดิจิทัล",
      metaTags: [
        {
          name: "keyword",
          content: "digital factoring,factoring,บริการรับซื้อหนี้การค้า,invoice,inet",
        },
        {
          name: "description",
          content:
            "Digital Factoring บริการรับซื้อหนี้การค้าเป็นสินเชื่อระยะสั้นที่จะทำให้คุณเปลี่ยนบิลหรือเอกกสาร ทางการค้าที่อยู่ในมือคุณเป็นเงินสดได้อย่างง่ายดาย โดยผ่านการตรวจสอบจากฐานข้อมูลกลางและมาตรฐานใบแจ้งหนี้ดิจิทัล",
        },

        {
          name: "author",
          content: "Internet Thailand Public Company Limited.",
        },
      ],
    },
  },
  {
    name: "Register",
    path: "/register",
    component: () => import("../views/Register.vue"),
    beforeEnter: ifMaintenance,
    meta: {
      title: "Register One Platform - ลงทะเบียนใช้งานระบบ Digital Factoring",
      metaTags: [
        {
          name: "keyword",
          content: "digital factoring,factoring,บริการรับซื้อหนี้การค้า,invoice,inet",
        },
        {
          name: "description",
          content:
            "Digital Factoring บริการรับซื้อหนี้การค้าเป็นสินเชื่อระยะสั้นที่จะทำให้คุณเปลี่ยนบิลหรือเอกกสาร ทางการค้าที่อยู่ในมือคุณเป็นเงินสดได้อย่างง่ายดาย โดยผ่านการตรวจสอบจากฐานข้อมูลกลางและมาตรฐานใบแจ้งหนี้ดิจิทัล",
        },

        {
          name: "author",
          content: "Internet Thailand Public Company Limited.",
        },
      ],
    },
  },
  {
    name: "RegieterBusiness",
    path: "/register-business",
    component: () => import("../views/RegisterBusiness.vue"),
    beforeEnter: ifMaintenance,
    meta: {
      title: "Regieter Business - ลงทะเบียนใช้งานระบบ Digital Factoring",
      metaTags: [
        {
          name: "keyword",
          content: "digital factoring,factoring,บริการรับซื้อหนี้การค้า,invoice,inet",
        },
        {
          name: "description",
          content:
            "Digital Factoring บริการรับซื้อหนี้การค้าเป็นสินเชื่อระยะสั้นที่จะทำให้คุณเปลี่ยนบิลหรือเอกกสาร ทางการค้าที่อยู่ในมือคุณเป็นเงินสดได้อย่างง่ายดาย โดยผ่านการตรวจสอบจากฐานข้อมูลกลางและมาตรฐานใบแจ้งหนี้ดิจิทัล",
        },

        {
          name: "author",
          content: "Internet Thailand Public Company Limited.",
        },
      ],
    },
  },
  {
    name: "Login",
    path: "/login",
    component: () => import("../views/Login.vue"),
    beforeEnter: ifMaintenance,
    meta: {
      title: "Register One Platform - ลงทะเบียนใช้งานระบบ Digital Factoring",
      metaTags: [
        {
          name: "keyword",
          content: "digital factoring,factoring,บริการรับซื้อหนี้การค้า,invoice,inet",
        },
        {
          name: "description",
          content:
            "Digital Factoring บริการรับซื้อหนี้การค้าเป็นสินเชื่อระยะสั้นที่จะทำให้คุณเปลี่ยนบิลหรือเอกกสาร ทางการค้าที่อยู่ในมือคุณเป็นเงินสดได้อย่างง่ายดาย โดยผ่านการตรวจสอบจากฐานข้อมูลกลางและมาตรฐานใบแจ้งหนี้ดิจิทัล",
        },

        {
          name: "author",
          content: "Internet Thailand Public Company Limited.",
        },
      ],
    },
  },
  {
    name: "Authorize Seller",
    path: "/authorize-seller",
    component: () => import("../views/page_authorize.vue"),
    beforeEnter: ifMaintenance,
    meta: {
      title: "Authorize Seller Digital Factoring",
      metaTags: [
        {
          name: "keyword",
          content: "digital factoring,factoring,บริการรับซื้อหนี้การค้า,invoice,inet",
        },
        {
          name: "description",
          content:
            "Digital Factoring บริการรับซื้อหนี้การค้าเป็นสินเชื่อระยะสั้นที่จะทำให้คุณเปลี่ยนบิลหรือเอกกสาร ทางการค้าที่อยู่ในมือคุณเป็นเงินสดได้อย่างง่ายดาย โดยผ่านการตรวจสอบจากฐานข้อมูลกลางและมาตรฐานใบแจ้งหนี้ดิจิทัล",
        },

        {
          name: "author",
          content: "Internet Thailand Public Company Limited.",
        },
      ],
    },
  },
  {
    name: "ContactUs",
    path: "/contact-us",
    component: () => import("../views/ContactUs.vue"),
    beforeEnter: ifMaintenance,
    meta: {
      title: "Contract us One Platform - ลงทะเบียนใช้งานระบบ Digital Factoring",
      metaTags: [
        {
          name: "keyword",
          content: "digital factoring,factoring,บริการรับซื้อหนี้การค้า,invoice,inet",
        },
        {
          name: "description",
          content:
            "Digital Factoring บริการรับซื้อหนี้การค้าเป็นสินเชื่อระยะสั้นที่จะทำให้คุณเปลี่ยนบิลหรือเอกกสาร ทางการค้าที่อยู่ในมือคุณเป็นเงินสดได้อย่างง่ายดาย โดยผ่านการตรวจสอบจากฐานข้อมูลกลางและมาตรฐานใบแจ้งหนี้ดิจิทัล",
        },

        {
          name: "author",
          content: "Internet Thailand Public Company Limited.",
        },
      ],
    },
  },
  {
    name: "Contents",
    path: "/contents/:id",
    component: () => import("../views/page_content.vue"),
    beforeEnter: ifMaintenance,
    meta: {
      title: "Content",
      metaTags: [
        {
          name: "keyword",
          content: "digital factoring,factoring,บริการรับซื้อหนี้การค้า,invoice,inet",
        },
        {
          name: "description",
          content:
            "Digital Factoring บริการรับซื้อหนี้การค้าเป็นสินเชื่อระยะสั้นที่จะทำให้คุณเปลี่ยนบิลหรือเอกกสาร ทางการค้าที่อยู่ในมือคุณเป็นเงินสดได้อย่างง่ายดาย โดยผ่านการตรวจสอบจากฐานข้อมูลกลางและมาตรฐานใบแจ้งหนี้ดิจิทัล",
        },

        {
          name: "author",
          content: "Internet Thailand Public Company Limited.",
        },
      ],
    },
  },
  {
    name: "Banner",
    path: "/webview-banner",
    component: () => import("../views/page_bannerWebview.vue"),
    beforeEnter: ifMaintenance,
    meta: {
      title: "Banner Contents",
      metaTags: [
        {
          name: "keyword",
          content: "digital factoring,factoring,บริการรับซื้อหนี้การค้า,invoice,inet",
        },
        {
          name: "description",
          content:
            "Digital Factoring บริการรับซื้อหนี้การค้าเป็นสินเชื่อระยะสั้นที่จะทำให้คุณเปลี่ยนบิลหรือเอกกสาร ทางการค้าที่อยู่ในมือคุณเป็นเงินสดได้อย่างง่ายดาย โดยผ่านการตรวจสอบจากฐานข้อมูลกลางและมาตรฐานใบแจ้งหนี้ดิจิทัล",
        },

        {
          name: "author",
          content: "Internet Thailand Public Company Limited.",
        },
      ],
    },
  },
  {
    name: "Consent",
    path: "/webview-consent",
    component: () => import("../views/page_consentWebview.vue"),
    beforeEnter: ifMaintenance,
    meta: {
      title: "Consent ",
      metaTags: [
        {
          name: "keyword",
          content: "digital factoring,factoring,บริการรับซื้อหนี้การค้า,invoice,inet",
        },
        {
          name: "description",
          content:
            "Digital Factoring บริการรับซื้อหนี้การค้าเป็นสินเชื่อระยะสั้นที่จะทำให้คุณเปลี่ยนบิลหรือเอกกสาร ทางการค้าที่อยู่ในมือคุณเป็นเงินสดได้อย่างง่ายดาย โดยผ่านการตรวจสอบจากฐานข้อมูลกลางและมาตรฐานใบแจ้งหนี้ดิจิทัล",
        },

        {
          name: "author",
          content: "Internet Thailand Public Company Limited.",
        },
      ],
    },
  },
  {
    name: "Register Biz",
    path: "/webview-registerbiz",
    component: () => import("../views/page_regisbizWebview.vue"),
    beforeEnter: ifMaintenance,
    meta: {
      title: "Register Business ",
      metaTags: [
        {
          name: "keyword",
          content: "digital factoring,factoring,บริการรับซื้อหนี้การค้า,invoice,inet",
        },
        {
          name: "description",
          content:
            "Digital Factoring บริการรับซื้อหนี้การค้าเป็นสินเชื่อระยะสั้นที่จะทำให้คุณเปลี่ยนบิลหรือเอกกสาร ทางการค้าที่อยู่ในมือคุณเป็นเงินสดได้อย่างง่ายดาย โดยผ่านการตรวจสอบจากฐานข้อมูลกลางและมาตรฐานใบแจ้งหนี้ดิจิทัล",
        },

        {
          name: "author",
          content: "Internet Thailand Public Company Limited.",
        },
      ],
    },
  },
  {
    name: "Edit Citizen",
    path: "/webview-useredit",
    component: () => import("../views/page_usereditWebview.vue"),
    beforeEnter: ifMaintenance,
    meta: {
      title: "User Edit ",
      metaTags: [
        {
          name: "keyword",
          content: "digital factoring,factoring,บริการรับซื้อหนี้การค้า,invoice,inet",
        },
        {
          name: "description",
          content:
            "Digital Factoring บริการรับซื้อหนี้การค้าเป็นสินเชื่อระยะสั้นที่จะทำให้คุณเปลี่ยนบิลหรือเอกกสาร ทางการค้าที่อยู่ในมือคุณเป็นเงินสดได้อย่างง่ายดาย โดยผ่านการตรวจสอบจากฐานข้อมูลกลางและมาตรฐานใบแจ้งหนี้ดิจิทัล",
        },

        {
          name: "author",
          content: "Internet Thailand Public Company Limited.",
        },
      ],
    },
  },
  {
    name: "Choosebusiness",
    path: "/webview-choosebusiness",
    component: () => import("../views/page_choosebusinessWebview.vue"),
    beforeEnter: ifMaintenance,
    meta: {
      title: "WebView - Choose Business",
      metaTags: [
        {
          name: "keyword",
          content: "digital factoring,factoring,บริการรับซื้อหนี้การค้า,invoice,inet",
        },
        {
          name: "description",
          content:
            "Digital Factoring บริการรับซื้อหนี้การค้าเป็นสินเชื่อระยะสั้นที่จะทำให้คุณเปลี่ยนบิลหรือเอกกสาร ทางการค้าที่อยู่ในมือคุณเป็นเงินสดได้อย่างง่ายดาย โดยผ่านการตรวจสอบจากฐานข้อมูลกลางและมาตรฐานใบแจ้งหนี้ดิจิทัล",
        },

        {
          name: "author",
          content: "Internet Thailand Public Company Limited.",
        },
      ],
    },
  },
  {
    name: "Request to ORG",
    path: "/webview-request_to_org",
    component: () => import("../views/page_requestorgWebview.vue"),
    beforeEnter: ifMaintenance,
    meta: {
      title: "Register Business ",
      metaTags: [
        {
          name: "keyword",
          content: "digital factoring,factoring,บริการรับซื้อหนี้การค้า,invoice,inet",
        },
        {
          name: "description",
          content:
            "Digital Factoring บริการรับซื้อหนี้การค้าเป็นสินเชื่อระยะสั้นที่จะทำให้คุณเปลี่ยนบิลหรือเอกกสาร ทางการค้าที่อยู่ในมือคุณเป็นเงินสดได้อย่างง่ายดาย โดยผ่านการตรวจสอบจากฐานข้อมูลกลางและมาตรฐานใบแจ้งหนี้ดิจิทัล",
        },

        {
          name: "author",
          content: "Internet Thailand Public Company Limited.",
        },
      ],
    },
  },
  {
    name: "Choosebusiness",
    path: "/webview-choosebusiness",
    component: () => import("../views/page_choosebusinessWebview.vue"),
    beforeEnter: ifMaintenance,
    meta: {
      title: "WebView - Choose Business",
      metaTags: [
        {
          name: "keyword",
          content: "digital factoring,factoring,บริการรับซื้อหนี้การค้า,invoice,inet",
        },
        {
          name: "description",
          content:
            "Digital Factoring บริการรับซื้อหนี้การค้าเป็นสินเชื่อระยะสั้นที่จะทำให้คุณเปลี่ยนบิลหรือเอกกสาร ทางการค้าที่อยู่ในมือคุณเป็นเงินสดได้อย่างง่ายดาย โดยผ่านการตรวจสอบจากฐานข้อมูลกลางและมาตรฐานใบแจ้งหนี้ดิจิทัล",
        },

        {
          name: "author",
          content: "Internet Thailand Public Company Limited.",
        },
      ],
    },
  },
  {
    name: "Request to ORG",
    path: "/webview-request_to_org",
    component: () => import("../views/page_requestorgWebview.vue"),
    beforeEnter: ifMaintenance,
    meta: {
      title: "Register Business ",
      metaTags: [
        {
          name: "keyword",
          content: "digital factoring,factoring,บริการรับซื้อหนี้การค้า,invoice,inet",
        },
        {
          name: "description",
          content:
            "Digital Factoring บริการรับซื้อหนี้การค้าเป็นสินเชื่อระยะสั้นที่จะทำให้คุณเปลี่ยนบิลหรือเอกกสาร ทางการค้าที่อยู่ในมือคุณเป็นเงินสดได้อย่างง่ายดาย โดยผ่านการตรวจสอบจากฐานข้อมูลกลางและมาตรฐานใบแจ้งหนี้ดิจิทัล",
        },

        {
          name: "author",
          content: "Internet Thailand Public Company Limited.",
        },
      ],
    },
  },
  {
    name: "Maintenance",
    path: "/maintenance",
    component: () => import("../views/page_maintenance.vue"),
    meta: {
      title: "Maintenance ",
      metaTags: [
        {
          name: "keyword",
          content: "digital factoring,factoring,บริการรับซื้อหนี้การค้า,invoice,inet",
        },
        {
          name: "description",
          content:
            "Digital Factoring บริการรับซื้อหนี้การค้าเป็นสินเชื่อระยะสั้นที่จะทำให้คุณเปลี่ยนบิลหรือเอกกสาร ทางการค้าที่อยู่ในมือคุณเป็นเงินสดได้อย่างง่ายดาย โดยผ่านการตรวจสอบจากฐานข้อมูลกลางและมาตรฐานใบแจ้งหนี้ดิจิทัล",
        },

        {
          name: "author",
          content: "Internet Thailand Public Company Limited.",
        },
      ],
    },
  },
  {
    name:"check-status-factor",
    path:"/check-status-factor",
    component: () => import("../views/page_checkStatusFactor.vue"),
    meta: {
      title: "ตรวจสอบสถานะการยื่นขอสินเชื่อ ",
      metaTags: [
        {
          name: "keyword",
          content: "digital factoring,factoring,บริการรับซื้อหนี้การค้า,invoice,inet,ตรวจสอบสถานะการยื่นขอสินเชื่อ",
        },
        {
          name: "description",
          content:
            "Digital Factoring บริการรับซื้อหนี้การค้าเป็นสินเชื่อระยะสั้นที่จะทำให้คุณเปลี่ยนบิลหรือเอกกสาร ทางการค้าที่อยู่ในมือคุณเป็นเงินสดได้อย่างง่ายดาย โดยผ่านการตรวจสอบจากฐานข้อมูลกลางและมาตรฐานใบแจ้งหนี้ดิจิทัล",
        },

        {
          name: "author",
          content: "Internet Thailand Public Company Limited.",
        },
      ],
    },
  },
];

async function ifMaintenance(to, from, next) {
  try {
    let maintenance = false;
    let call_api = await axios.get(process.env.VUE_APP_API_FACTORING_SERVICE + "/nontification/svmaintenance");
    maintenance = call_api.data.message;
    if (maintenance) {
      next("/maintenance");
    } else {
      next();
    }
  } catch (error) {
    console.log("error in ifMaintenance", error);
    next();
  }
}

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
  duplicateNavigationPolicy: "ignore",
  scrollBehavior() {
    return { x: 0, y: 0 };
  },
  linkActiveClass: "active", // active class for non-exact links.
  linkExactActiveClass: "active",
});

router.beforeEach((to, from, next) => {
  const nearestWithTitle = to.matched
    .slice()
    .reverse()
    .find((r) => r.meta && r.meta.title);

  // Find the nearest route element with meta tags.
  const nearestWithMeta = to.matched
    .slice()
    .reverse()
    .find((r) => r.meta && r.meta.metaTags);

  const previousNearestWithMeta = from.matched
    .slice()
    .reverse()
    .find((r) => r.meta && r.meta.metaTags);

  // If a route with a title was found, set the document (page) title to that value.
  if (nearestWithTitle) {
    document.title = nearestWithTitle.meta.title;
  } else if (previousNearestWithMeta) {
    document.title = previousNearestWithMeta.meta.title;
  }

  // Remove any stale meta tags from the document using the key attribute we set below.
  Array.from(document.querySelectorAll("[data-vue-router-controlled]")).map((el) => el.parentNode.removeChild(el));
  // CHECK LANGUAGE
  if (localStorage.getItem("lang") === true && localStorage.getItem("lang") !== i18n.locale) {
    i18n.locale = localStorage.getItem("lang");
  } else if (!localStorage.getItem("lang")) {
    localStorage.setItem("lang", "th");
    i18n.locale = localStorage.getItem("lang");
  }
  // Skip rendering meta tags if there are none.
  if (!nearestWithMeta) return next();

  // Turn the meta tag definitions into actual elements in the head.
  nearestWithMeta.meta.metaTags
    .map((tagDef) => {
      const tag = document.createElement("meta");

      Object.keys(tagDef).forEach((key) => {
        tag.setAttribute(key, tagDef[key]);
      });

      // We use this to track which meta tags we create so we don't interfere with other ones.
      tag.setAttribute("data-vue-router-controlled", "");

      return tag;
    })
    // Add the meta tags to the document head.
    .forEach((tag) => document.head.appendChild(tag));

  next();
});
// Enable Google Analytics
Vue.use(
  VueGtag,
  {
    config: {
      enabled: process.env.NODE_ENV === "production",
      // enabled: true,
      id: process.env.VUE_APP_GTAG_ID,
    },
    appName: process.env.VUE_APP_GTAG_APPNAME,
    pageTrackerScreenviewEnabled: true,
  },
  router
);

export default router;
