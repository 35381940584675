<template>
  <v-app id="app">
    <!-- <LoadingPage/> -->
    <router-view />
  </v-app>
</template>

<script>
// import LoadingPage from "@/components/LoadingPage.vue";

// import footer from '@/components/footer.vue';
// import register from '@/components/register';
// import Home1 from '@/views/Home1.vue';
// import Navbar from '@/components/Navbar.vue';

export default {
  name: "App",
  // components: {LoadingPage}
};
</script>

<style>
/* @import url("https://fonts.googleapis.com/css2?family=Sarabun&display=swap"); */
/*@import url("https://fonts.googleapis.com/css2?family=Prompt:wght@400;600&display=swap"); */

@import url("https://fonts.googleapis.com/css2?family=Kanit:wght@300;400;500&display=swap");
/* #app {
  font-family: "Kanit", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #211884;
}

.swal2-container {
  font-family: "Kanit", sans-serif !important;
} */
.swal2-actions button {
  color: #fff;
}
</style>
